import React, { useState, useMemo } from 'react';
import {
  Grid,
  useMediaQuery,
  Typography,
  withStyles,
  Box,
  Theme
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import ReviewCards from '@components/ReviewCard';
import { reviews } from '@utils/constants';
import { WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  typoMore: {
    fontSize: theme.spacing(3),
    fontWeight: 600
  },
  button: {
    display: 'flex',
    cursor: 'pointer'
  }
});

interface ReviewSectionProps extends WithStyles<typeof styles> {
  theme: Theme;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ theme, classes }) => {
  const [showMoreReviews, setShowMoreReviews] = useState(false);

  const { buttonIcon: ButtonIcon, buttonText } = useMemo(() => {
    let button = ExpandMore;
    let text = 'Mer';
    if (showMoreReviews) {
      button = ExpandLess;
      text = 'Mindre';
    }
    return { buttonIcon: button, buttonText: text };
  }, [showMoreReviews]);

  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Grid container spacing={matches ? 3 : 8} direction="column">
      <Grid item container spacing={matches ? 4 : 10}>
        {(showMoreReviews ? reviews : reviews.slice(0, 3)).map((item) => (
          <Grid item xs={12} sm={4} key={item.name}>
            <ReviewCards item={item} />
          </Grid>
        ))}
      </Grid>
      {reviews.length > 3 && (
        <Grid item container justify="center">
          <Box
            className={classes.button}
            onClick={() => {
              setShowMoreReviews((currState) => !currState);
            }}
          >
            <Typography className={classes.typoMore} color="primary">
              {buttonText}
            </Typography>
            <ButtonIcon color="primary" fontSize="large" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ReviewSection);
