import React from 'react';
import {
  withStyles,
  Typography,
  Box,
  Grid,
  Avatar,
  Theme
} from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  container: {
    ...theme.shadowPrimary,
    border: `1px solid #FFF`,
    borderRadius: 16,
    padding: theme.spacing(4)
  },
  avatar: {
    width: theme.spacing(11),
    height: theme.spacing(11)
  },
  titleShared: {
    fontWeight: 600
  },
  title: {
    fontSize: 22
  },
  textReview: {
    overflow: 'auto',
    height: 170
  }
});

interface ReviewCardProps extends WithStyles<typeof styles> {
  item: {
    name: string;
    profession: string;
    text: string;
    image: string;
  };
  theme: Theme;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ classes, theme, item }) => (
  <Box className={classes.container}>
    <Grid container direction="column" spacing={4}>
      <Grid item container spacing={3}>
        <Grid item>
          <Avatar
            className={classes.avatar}
            src={item.image}
            alt="review image"
            imgProps={{
              width: theme.spacing(11),
              height: theme.spacing(11)
            }}
          />
        </Grid>
        <Grid item xs>
          <Typography className={`${classes.title} ${classes.titleShared}`}>
            {item.name}
          </Typography>
          <Typography className={classes.titleShared} color="textPrimary">
            {item.profession}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.textReview}>
        <Typography>{item.text}</Typography>
      </Grid>
    </Grid>
  </Box>
);

export default withStyles(styles, { withTheme: true })(ReviewCard);
