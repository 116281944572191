import React, { useMemo } from 'react';
import {
  Container,
  Theme,
  useMediaQuery,
  WithStyles,
  withStyles
} from '@material-ui/core';
import VideoSection from '@components/sections/VideoSection';
import TextImageSection from '@components/sections/TextImageSection';
import ServiceSection from '@components/sections/ServiceSection';
import ReviewSection from '@components/sections/ReviewSection';
import Spacer from '@components/shared/Spacer';
import HeroBanner from '@components/HeroBanner';
import { textImageData } from '@utils/constants';
import SEO from '@components/shared/seo';
import { graphql, useStaticQuery } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';

const styles = () => ({});

interface LandingPageProps extends WithStyles<typeof styles> {
  theme: Theme;
}

const LandingPage: React.FC<LandingPageProps> = ({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const queryData = useStaticQuery(query);

  const textImages = useMemo(() => {
    if (queryData) {
      return textImageData.map((item, index) => ({
        ...item,
        image: queryData.figuresLarge.nodes[index].childImageSharp.fluid,
        shapeSmall: queryData.shapesSmall.nodes[index].childImageSharp.fluid,
        shapeLarge: queryData.shapesLarge.nodes[index].childImageSharp.fluid
      }));
    }
    return [];
  }, [queryData]);

  return (
    <ParallaxProvider>
      <SEO title="👋" />
      <Container>
        <HeroBanner />
        <Spacer spacing={13} />
        <VideoSection />
        <Spacer spacing={10} />
        <ServiceSection queryData={queryData?.figuresSmall.nodes} />
        <Spacer spacing={19} />
        <ReviewSection />
        <Spacer spacing={isMobile ? 10 : 30} />
        {textImages.map((item, index) => (
          <React.Fragment key={item.title}>
            <TextImageSection
              item={item}
              index={index}
              id={`imageSection${index}`}
              direction={index % 2 === 0 ? 'row' : 'row-reverse'}
              onClick={
                index === textImageData.length - 1
                  ? () => {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      });
                    }
                  : null
              }
            />
            {index < textImages.length - 1 && (
              <Spacer spacing={isMobile ? 20 : 30} />
            )}
          </React.Fragment>
        ))}
      </Container>
    </ParallaxProvider>
  );
};

export const query = graphql`
  query {
    figuresSmall: allFile(
      filter: { sourceInstanceName: { eq: "figures_small" } }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 305, quality: 100, fit: CONTAIN) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    figuresLarge: allFile(
      filter: { sourceInstanceName: { eq: "figures_large" } }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100, fit: CONTAIN) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    shapesLarge: allFile(
      filter: { sourceInstanceName: { eq: "shapes_large" } }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    shapesSmall: allFile(
      filter: { sourceInstanceName: { eq: "shapes_small" } }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  }
`;

export default withStyles(styles, { withTheme: true })(LandingPage);
