import { Box, Grid, Theme, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import Spacer from '@components/shared/Spacer';
import { WithStyles } from '@material-ui/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
const videoUrlWebm =
  'https://firebasestorage.googleapis.com/v0/b/easyeducation-f9cd0.appspot.com/o/landing_page%2Fvideo.webm?alt=media&token=74acbc94-cfaf-4561-a8a4-400aec153c8d';
const videoUrlMp4 =
  'https://firebasestorage.googleapis.com/v0/b/easyeducation-f9cd0.appspot.com/o/landing_page%2Fvideo.mp4?alt=media&token=465a1379-a596-4227-9ce2-8e796f502e47';

const styles = (theme: Theme) => ({
  title: {
    fontWeight: 600,
    fontSize: 36,
    [theme.breakpoints.down('md')]: {
      fontSize: 26
    }
  },
  video: {
    outline: 'none',
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});

interface IVideoSection extends WithStyles<typeof styles> {
  theme: Theme;
}

const VideoSection: React.FC<IVideoSection> = ({ theme, classes }) => {
  const CustomBulletList: React.FC = ({ children }) => (
    <Box display="flex">
      <ArrowRightIcon color="primary" />
      <Typography
        color="textPrimary"
        variant={theme.breakpoints.down('md') ? 'h6' : 'h5'}
      >
        {children}
      </Typography>
    </Box>
  );

  return (
    <Grid container direction="column">
      <Typography color="textPrimary" className={classes.title}>
        Anmäl ditt intresse och bli en av de första som får ett{' '}
        <Typography color="primary" variant="inherit">
          konto
        </Typography>
      </Typography>
      <Spacer />

      <CustomBulletList>Nytt material varje vecka</CustomBulletList>
      <CustomBulletList>
        Gamla tentor, sammanfattningar och mycket mer...
      </CustomBulletList>
      <CustomBulletList>
        Få direkt tillgång till materialet och testa alla verktygen
      </CustomBulletList>
      <Spacer spacing={6} />
      <Grid item container justify="center">
        <video
          preload="metadata"
          height="100%"
          controls
          className={classes.video}
        >
          <source src={videoUrlWebm} type="video/webm" />
          <source src={videoUrlMp4} type="video/mp4" />
        </video>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(VideoSection);
