import React from 'react';
import {
  Button,
  createStyles,
  Grid,
  GridDirection,
  Theme,
  Typography,
  useMediaQuery,
  withStyles,
  WithStyles
} from '@material-ui/core';
import Spacer from '@components/shared/Spacer';
import Img, { FluidObject } from 'gatsby-image';
import { Parallax } from 'react-scroll-parallax';

const styles = (theme: Theme) =>
  createStyles({
    typoTitle: {
      fontWeight: 600,
      fontSize: 36,
      [theme.breakpoints.down('md')]: {
        fontSize: 30
      }
    },
    containerImage: {
      position: 'relative'
    },
    image: {
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '85%'
      }
    },
    containerShape: {
      position: 'absolute'
    },
    shape: {
      position: 'absolute !important',
      zIndex: theme.zIndex.appBar,
      width: '100%'
    },
    button: {
      color: theme.palette.text.primary,
      fontWeight: 600
    }
  });

interface TextImageSectionProps extends WithStyles<typeof styles> {
  theme: Theme;
  direction: GridDirection;
  item: {
    title: string;
    body: string;
    image: FluidObject;
    shapeSmall: FluidObject;
    shapeLarge: FluidObject;
    shapeSmallStyle: any;
    shapeLargeStyle: any;
  };
  id: string;
  onClick: (() => void) | null;
  index: number;
}

const TextImageSection: React.FC<TextImageSectionProps> = ({
  classes,
  theme,
  direction = 'row',
  item,
  onClick = null,
  id,
  index
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Grid
      id={id}
      container
      direction={direction}
      alignItems="center"
      justify="space-between"
      spacing={4}
    >
      <Grid item xs={12} md={5}>
        <Typography className={classes.typoTitle} color="textPrimary">
          {item.title}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {item.body}
        </Typography>
        {onClick && (
          <>
            <Spacer spacing={3} />
            <Button
              color="primary"
              size="large"
              variant="contained"
              className={classes.button}
              onClick={onClick}
            >
              anmäl intresse
            </Button>
          </>
        )}
      </Grid>
      <Grid item xs className={classes.containerImage}>
        <Parallax
          x={index % 2 === 0 ? [-5, 5] : [5, -5]}
          y={index % 2 === 0 ? [10, -10] : [-10, 10]}
        >
          <Img
            fluid={item.image}
            className={classes.image}
            alt="figure drawing"
          />
        </Parallax>
        {!isMobile && (
          <Img
            style={{
              ...(isLarge ? item.shapeLargeStyle : item.shapeSmallStyle)
            }}
            fluid={isLarge ? item.shapeLarge : item.shapeSmall}
            className={classes.shape}
            alt="background shape"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(TextImageSection);
