import React, { useState } from 'react';
import {
  withStyles,
  Box,
  TextField,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Theme,
  WithStyles
} from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import Spacer from '@components/shared/Spacer';
import SignUpResultView from '@components/SignUpReslutView';
import { SignUpSchema } from '@utils/validation';
import { signUpFields } from '@utils/constants';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// import getRunTimeConfig from '@config';
// const config = getRunTimeConfig();

const formName = 'interestForm';

const styles = (theme: Theme) => ({
  container: {
    ...theme.shadowPrimary,
    border: '1px solid #FFFFFF',
    borderRadius: 16,
    padding: theme.spacing(4, 6)
  },
  button: {
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  logo: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

interface IValues {
  email: string;
  emailConfirm: string;
  school: string;
  program: string;
  firstName: string;
  lastName: string;
  allowNews: boolean;
}

const initialValues = {
  email: '',
  emailConfirm: '',
  school: '',
  program: '',
  firstName: '',
  lastName: '',
  // TODO: Add these fields when ee platform has been updated w/ new features
  // password: '',
  // passwordConfirm: '',
  allowNews: false
};

const SignUpForm: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const queryData = useStaticQuery(query);

  const encode = (data: { [key: string]: string | boolean }): FormData => {
    const formData = new FormData();
    Object.keys(data).forEach((key) =>
      formData.append(key, data[key].toString())
    );
    return formData;
  };

  const onSubmit = async (values: IValues, actions: FormikHelpers<IValues>) => {
    try {
      // TODO: Add these fields when ee platform has been updated w/ new features
      // const res = await fetch(
      //   `${config.baseUrl}${config.endpoints.createUser}`,
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //       email: values.email,
      //       password: values.password,
      //       firstName: values.firstName || '',
      //       lastName: values.lastName || '',
      //       allowNews: !!values.allowNews
      //     })
      //   }
      // );
      // const json = await res.json();
      // setMessage(json.message);
      // setIsError(json.error);
      const response = await fetch('/', {
        method: 'POST',
        body: encode({
          'form-name': formName,
          ...values
        })
      });
      setMessage('Tack för din intresseanmälan!');
      setIsError(!response.ok);
      actions.resetForm();
    } catch (e) {
      setMessage('Kunde inte skicka intresseanmälan, vänligen försök igen');
      setIsError(true);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const resetResult = () => {
    setMessage('');
    setIsError(false);
  };

  const Input: React.FC<{ [key: string]: any }> = ({ field, ...props }) => (
    <TextField
      fullWidth
      variant="outlined"
      color="primary"
      size="small"
      {...field}
      {...props}
    />
  );

  const ErrorHint: React.FC<{ [key: string]: any }> = (props) => (
    <Typography {...props} color="error" variant="caption" />
  );

  const Checkbox: React.FC<{ [key: string]: any }> = ({ field, ...props }) => (
    <FormGroup row>
      <FormControlLabel
        control={<MuiCheckbox color="primary" {...field} {...props} />}
        label={
          <Typography component="label" variant="subtitle2">
            {props.label}
          </Typography>
        }
      />
    </FormGroup>
  );

  return (
    <Box className={classes.container}>
      <Img
        className={classes.logo}
        fluid={queryData.logo.childImageSharp.fluid}
        alt="easy education logo"
      />
      <Spacer spacing={5} />
      <Box position="relative">
        <Formik
          onSubmit={onSubmit}
          validationSchema={SignUpSchema}
          initialValues={initialValues}
        >
          {({ isSubmitting }) => (
            <Form
              name={formName}
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input hidden name="bot-field" />
              {signUpFields.map((field) => (
                <React.Fragment key={field.name}>
                  <Field
                    {...field}
                    disabled={isSubmitting}
                    component={field.name === 'allowNews' ? Checkbox : Input}
                  />
                  <ErrorMessage name={field.name} component={ErrorHint} />
                  <Spacer spacing={3} />
                </React.Fragment>
              ))}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                className={classes.button}
                disabled={isSubmitting}
              >
                anmäl intresse
              </Button>
            </Form>
          )}
        </Formik>
        <SignUpResultView
          message={message}
          isError={isError}
          onClose={resetResult}
        />
      </Box>
    </Box>
  );
};

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.webp" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100, fit: CONTAIN) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;

export default withStyles(styles)(SignUpForm);
