import {
  Box,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import React, { useMemo } from 'react';
import Spacer from '@components/shared/Spacer';
import Img, { FluidObject, GatsbyImageFluidProps } from 'gatsby-image';
import { serviceTitles } from '@utils/constants';
import { Parallax } from 'react-scroll-parallax';

const styles = (theme: Theme) => ({
  title: {
    fontWeight: 600,
    fontSize: 36,
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    }
  },
  titleService: {
    fontSize: 36,
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    }
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  typoButton: {
    fontWeight: 600,
    fontSize: 26,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    }
  },
  wrapperImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
interface GridColumnProps {
  onClick: () => void;
  image: FluidObject;
  title: string;
  index: number;
}

interface NodeProps {
  childImageSharp: {
    fluid: GatsbyImageFluidProps;
  };
}

interface ServiceSectionProps extends WithStyles<typeof styles> {
  theme: Theme;
  queryData: NodeProps[];
}

const ServiceSection: React.FC<ServiceSectionProps> = ({
  classes,
  theme,
  queryData
}) => {
  const mdDownMatch = useMediaQuery(theme.breakpoints.down('md'));

  const data = useMemo(() => {
    if (queryData) {
      return queryData.map((node: NodeProps, index: number) => ({
        image: node.childImageSharp.fluid,
        title: serviceTitles[index]
      }));
    }
    return [];
  }, [queryData]);

  const GridColumn: React.FC<GridColumnProps> = ({
    onClick,
    image,
    title,
    index
  }) => (
    <>
      <Parallax y={[20, -20]}>
        <Img
          fluid={image}
          style={{ display: 'block', margin: 'auto' }}
          alt="figure"
        />
      </Parallax>
      <Spacer spacing={mdDownMatch ? 4 : 9} />
      <Typography
        color="textPrimary"
        align="center"
        className={classes.titleService}
      >
        {title}
      </Typography>
      <Box className={classes.button} onClick={onClick}>
        <Typography color="primary" className={classes.typoButton}>
          Läs mer
        </Typography>
        <Spacer orientation="horizontal" />
        <ArrowRightAlt color="primary" fontSize="large" />
      </Box>
    </>
  );

  return (
    <Grid container direction="column">
      <Typography color="textPrimary" className={classes.title}>
        Varför Easy Education?
      </Typography>
      <Spacer spacing={6} />
      <Grid
        item
        container
        alignItems="flex-end"
        justify="center"
        spacing={mdDownMatch ? 10 : 0}
      >
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={item.title}>
            <GridColumn
              {...item}
              index={index}
              onClick={() => {
                const el = document.getElementById(`imageSection${index + 1}`);
                if (el) {
                  el.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ServiceSection);
