import React, { useMemo } from 'react';
import {
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  WithStyles,
  withStyles
} from '@material-ui/core';
import SignUpForm from '@components/SignUpForm';
import Spacer from '@components/shared/Spacer';
import { Parallax } from 'react-scroll-parallax';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const styles = () => ({
  title: {
    fontWeight: 600
  }
});

interface HeroBannerProps extends WithStyles<typeof styles> {
  theme: Theme;
}

const HeroBanner: React.FC<HeroBannerProps> = ({ classes, theme }) => {
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const queryData = useStaticQuery(query);

  const mainSpacing = useMemo(() => {
    if (matchesMdUp) {
      return 10;
    }
    if (!matchesSmDown) {
      return 5;
    }
    return 0;
  }, [matchesMdUp, matchesSmDown]);

  return (
    <Grid
      container
      spacing={mainSpacing}
      direction={matchesSmDown ? 'column-reverse' : 'row'}
    >
      <Grid item xs={12} sm={6} md={5} lg={4}>
        <SignUpForm />
      </Grid>
      <Grid item xs={12} sm={6} md={matchesSmDown ? 8 : 6}>
        <Parallax y={matchesSmDown ? [-5, 5] : [-40, 40]}>
          <Typography
            variant={matchesMdUp ? 'h2' : 'h4'}
            color="textPrimary"
            className={classes.title}
          >
            Education made{' '}
            <Typography variant="inherit" color="primary">
              easy
            </Typography>
          </Typography>
          <Spacer />
          <Typography variant={matchesMdUp ? 'h5' : 'h6'} color="textPrimary">
            Direkt åtkomst till billigt, smart och interaktivt kursmaterial
            online.
          </Typography>
          <Spacer spacing={3} />
          <Grid container item justify="space-between">
            <Grid item xs={12}>
              <Img
                fluid={queryData.frame.childImageSharp.fluid}
                alt="easy education laptop frame"
              />
            </Grid>
            {/* <Grid item xs={3} />
            <Grid item xs={9}>
              <Img
                fluid={queryData.frame2.childImageSharp.fluid}
                alt="easy education laptop frame"
              />
            </Grid> */}
          </Grid>
        </Parallax>
      </Grid>
    </Grid>
  );
};

export const query = graphql`
  query {
    frame: file(relativePath: { eq: "frame.webp" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    frame2: file(relativePath: { eq: "frame2.webp" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default withStyles(styles, { withTheme: true })(HeroBanner);
