import React from 'react';
import {
  withStyles,
  Box,
  Button,
  Typography,
  Theme,
  WithStyles,
  createStyles
} from '@material-ui/core';
import Spacer from './shared/Spacer';

const styles = (theme: Theme) =>
  createStyles({
    containerResult: {
      transition: 'top 1s',
      position: 'absolute',
      left: -10,
      right: -10,
      top: 500,
      background: '#E5E5E5',
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    showResult: {
      top: -10,
      bottom: -10
    },
    buttonSuccess: {
      color: theme.palette.text.primary,
      fontWeight: 600
    },
    buttonError: {
      color: '#FFF',
      fontWeight: 600
    }
  });

interface ResultViewProps extends WithStyles<typeof styles> {
  message: string;
  isError: boolean;
  onClose: () => void;
}

const ResultView: React.FC<ResultViewProps> = ({
  message,
  isError,
  classes,
  onClose
}) => (
  <Box
    className={[classes.containerResult, !!message && classes.showResult].join(
      ' '
    )}
  >
    {message && (
      <>
        <Typography align="center">{message}</Typography>
        <Spacer spacing={3} />
        <Button
          onClick={onClose}
          className={isError ? classes.buttonError : classes.buttonSuccess}
          variant="contained"
          color="secondary"
        >
          Tillbaka
        </Button>
      </>
    )}
  </Box>
);

export default withStyles(styles)(ResultView);
