import * as Yup from 'yup';

export const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email('Ogiltigt e-post')
    .required('Vänligen ange din e-postaddress'),
  emailConfirm: Yup.string()
    .oneOf([Yup.ref('email'), undefined], 'E-post överensstämmer ej')
    .required('Vänligen bekräfta e-postaddress'),
  school: Yup.string()
    .min(3, 'För kort (minst 3 tecken')
    .max(50, 'För långt (max 50 tecken)'),
  program: Yup.string()
    .min(3, 'För kort (minst 3 tecken')
    .max(50, 'För långt (max 50 tecken)')
  // TODO: Add these fields when ee platform has been updated w/ new features
  // password: Yup.string()
  //   .min(10, 'För kort (minst 10 tecken)')
  //   .max(50, 'För långt (max 50 tecken)')
  //   .matches(
  //     /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
  //     'Måste innehålla minst 1 stor bokstav och 1 siffra'
  //   )
  //   .required('Vänligen ange ett lösenord'),
  // passwordConfirm: Yup.string()
  //   .oneOf([Yup.ref('password'), undefined], 'Lösenord överensstämmer ej')
  //   .required('Vänligen bekräfta lösenord')
});
